import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import InstagramContentService from "../services/instagramcontent.service";
import instagramContentService from "src/services/instagramcontent.service";

// Create (Add) a new Instagram content
export const addInstagramContent = createAsyncThunk(
  "instagramContent/add",
  async ({ url, thumbnail, isActive, sort_order }, thunkAPI) => {
    try {
      const data = await InstagramContentService.addInstagramContent(url, thumbnail,isActive, sort_order);
      thunkAPI.dispatch(setMessage(data.message));
      return data.content;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getInstagramContent = createAsyncThunk(
  "instagramContent/get",
  async (thunkAPI) => {
    try {
      const data = await instagramContentService.getInstagramContent();
      return data.docs;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getActiveInstagramContent = createAsyncThunk(
  "instagramContent/get/active",
  async (thunkAPI) => {
    try {
      const data = await instagramContentService.getActiveInstagramContent();
      return data.docs;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInstagramContentById = createAsyncThunk(
  "instagramContent/getById",
  async (id) => {
    try {
      const data = await instagramContentService.getInstagramContentById(id)
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);



export const updateInstagramContent = createAsyncThunk(
  "instagramContent/update",
  async ({ id, updateData }, thunkAPI) => {
    try {
      const data = await instagramContentService.updateInstagramContent(id, updateData.thumbnail, updateData.url,  updateData.sort_order, updateData.isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.doc;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInstagramContent = createAsyncThunk(
  "instagramContent/delete",
  async (id, thunkAPI) => {
    try {
      const data = await instagramContentService.deleteInstagramContent(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateInstagramContentOrder = createAsyncThunk(
  "instagramContent/updateOrder",
  async (platforms, thunkAPI) => {
    try {
      const response = await instagramContentService.updateInstagramContentOrder(platforms);
      thunkAPI.dispatch(setMessage(response.message));
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


 
const initialState = { isLoading: false, content: [], singleContent: null };

const instagramContentSlice = createSlice({
  name: "instagramContent",
  initialState,
  extraReducers: {
    [addInstagramContent.pending]: (state) => {
      state.isLoading = true;
    },
    [addInstagramContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [addInstagramContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [getInstagramContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [getInstagramContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [updateInstagramContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [updateInstagramContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [deleteInstagramContent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.content = action.payload;
    },
    [deleteInstagramContent.rejected]: (state) => {
      state.isLoading = false;
      state.content = null;
    },
    [getInstagramContentById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.singleContent = action.payload;
    },
    [getInstagramContentById.rejected]: (state) => {
      state.isLoading = false;
      state.singleContent = null;
    },
  },
});

const { reducer } = instagramContentSlice;
export default reducer;
