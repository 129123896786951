import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import "./style.css";
import Login from "./views/admin/pages/login/Login";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Routes
const AdminRoutes = React.lazy(() => import("./AdminRoutes"));
const UserRoutes = React.lazy(() => import("./UserRoutes"));
const InstagramContentRoutes = React.lazy(() => import("./InstagramContentRoutes"));
class App extends Component {
  render() {
    return (
      <Router>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/dl/lognow/adm" name="Login Page" element={<Login />} />
            <Route exect path="/admin/*" element={<AdminRoutes />} />
            <Route exect path="/instagram-content" element={<InstagramContentRoutes />} />
            <Route exect path="/*" element={<UserRoutes />} />
          </Routes>
        </Suspense>
      </Router>
    );
  }
}

export default App;
