import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/girlsproducts.service";

export const addGirlsProduct = createAsyncThunk(
  "girlsproduct/add",
  async ({ title, url, price, description, image, isActive, discountPrice }, thunkAPI) => {
    try {
      const data = await AuthService.addGirlsProduct(
        title,
        url,
        price,
        description,
        image,
        isActive,
        discountPrice
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getGirlsProduct = createAsyncThunk(
  "girlsproduct/get",
  async (thunkAPI) => {
    try {
      const data = await AuthService.getGirlsProduct();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateGirlsProductSponsored = createAsyncThunk(
  "girlsproduct/updateSponsored",
  async ({ id, isSponsored }, thunkAPI) => {
    try {
      const data = await AuthService.updateGirlsProductSponsored(id, isSponsored);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateGirlsProduct = createAsyncThunk(
  "girlsproduct/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateGirlsProduct(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateGirlsProductData = createAsyncThunk(
  "girlsproduct/updateInfo",
  async ({ id, title, url, price, description, image, oldImage, discountPrice }, thunkAPI) => {
    try {
      const data = await AuthService.updateGirlsProductData(
        id,
        title,
        url,
        price,
        description,
        image,
        oldImage,
        discountPrice
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteGirlsProduct = createAsyncThunk(
  "girlsproduct/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteGirlsProduct(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, girlsproduct: null };

const girlsProductSlice = createSlice({
  name: "girlsproduct",
  initialState,
  extraReducers: {
    [addGirlsProduct.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = null;
    },
    [addGirlsProduct.rejected]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = null;
    },
    [getGirlsProduct.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = action.payload;
    },
    [getGirlsProduct.rejected]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = null;
    },
    [updateGirlsProduct.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = action.payload;
    },
    [updateGirlsProduct.rejected]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = null;
    },
    [updateGirlsProductData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = action.payload;
    },
    [updateGirlsProductData.rejected]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = null;
    },
    [deleteGirlsProduct.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = action.payload;
    },
    [deleteGirlsProduct.rejected]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = null;
    },
    [updateGirlsProductSponsored.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = action.payload;
    },
    [updateGirlsProductSponsored.rejected]: (state, action) => {
      state.isLoader = false;
      state.girlsproduct = null;
    },
  },
});

const { reducer } = girlsProductSlice;
export default reducer;
