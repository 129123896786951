import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;



const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addVotedProduct = async (title, image, url) => {
  return await axios
    .post(API_URL + "admin/addvotedproduct", {
      title,
      image,
      url,
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getVotedProducts = async () => {
  return await instance
    .get(API_URL + "admin/votedproducts", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};


const updateVotedProduct = async (id, title, image, oldImage, url) => {
  return await axios
    .put(API_URL + `admin/updatevotedproduct/${id}`, {
      title,
      image,
      oldImage,
      url
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteVotedProduct = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletevotedproduct/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const updateVotedProductOrder = async (votedProducts) => {
  return await axios.post(API_URL + "admin/updatevotedproductorder", { votedProducts }, { headers: authHeader() });
};

const digitalIssueService = { addVotedProduct, getVotedProducts, updateVotedProduct, deleteVotedProduct, updateVotedProductOrder };
export default digitalIssueService;