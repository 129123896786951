import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;



const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addInstagramContent = async ( url, thumbnail, isActive, sort_order ) => {
  return await axios.post(
    API_URL + "admin/addInstagramContent",
    {
      url,
      thumbnail,
      isActive,
      sort_order,
    },
    {
      headers: authHeader(),
    }
  ).then((response) => {
    return response.data;
  });
};

const getInstagramContent = async () => {
  return await instance.get(API_URL + "admin/instagramContent", {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const getActiveInstagramContent = async () => {
  return await axios.get(API_URL + "user/activeinstagramcontent", {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};


const updateInstagramContent = async (id, thumbnail, url, sort_order, isActive) => {
  return await axios.patch(
    API_URL + `admin/updateinstagramcontent/${id}`,
    { thumbnail, url, sort_order, isActive },
    {
      headers: authHeader(),
    }
  ).then((response) => {
    return response.data;
  });
};


const deleteInstagramContent = async (id) => {
  return await axios.delete(API_URL + `admin/deleteInstagramContent/${id}`, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const getInstagramContentById = async (id) => {
  return await axios.get(API_URL + `admin/instagramcontent/${id}`, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
}

const updateInstagramContentOrder = async (platforms) => {
  return await axios.post(API_URL + "admin/updateInstagramContentOrder", { platforms }, {
    headers: authHeader(),
  }).then((response) => {
    return response.data;
  });
};

const instagramContentService = {
  addInstagramContent,
  getInstagramContent,
  updateInstagramContent,
  deleteInstagramContent,
  getInstagramContentById,
  updateInstagramContentOrder,
  getActiveInstagramContent
};
export default instagramContentService;
