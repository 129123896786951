import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;


const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);
const addBanner = async (title, url, description, image, imageMobile, isActive) => {
  return await axios
    .post(API_URL + "admin/addbanner", {
      title,
      url,
      description,
      image,
      imageMobile,
      isActive,
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getBanner = async () => {
  return await instance
    .get(API_URL + "admin/banner", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};


const updateBanner = async (id, isActive) => {
  return await axios
    .patch(API_URL + `admin/updatebanner/${id}`, { isActive }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const updateBannerData = async (id, title, url, description, image, oldImage, imageMobile, oldImageMobile) => {
  return await axios
    .put(API_URL + `admin/updatebannerData/${id}`, {
      title,
      url,
      description,
      image,
      oldImage,
      imageMobile,
      oldImageMobile
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteBanner = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletebanner/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const bannerService = { addBanner, getBanner, updateBanner, updateBannerData, deleteBanner };
export default bannerService;
