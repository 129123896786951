import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/votedproduct.service";

export const addVotedProduct = createAsyncThunk(
  "voted/add", async ({ title, image, url }, thunkAPI) => {

    try {
      const data = await AuthService.addVotedProduct(title, image, url);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getVotedProducts = createAsyncThunk(
  "voted/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getVotedProducts();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateVotedProduct = createAsyncThunk(
  "voted/updateInfo", async ({ id, title, image, oldImage, url }, thunkAPI) => {
    try {
      const data = await AuthService.updateVotedProduct(id, title, image, oldImage, url);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  });

export const deleteVotedProduct = createAsyncThunk(
  "voted/delete", async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteVotedProduct(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }

);

export const updateVotedProductOrder = createAsyncThunk(
  "voted/updateOrder", async (votedProducts, thunkAPI) => {
    try {
      const data = await AuthService.updateVotedProductOrder(votedProducts);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, voted: null };

const VotedProductSlice = createSlice({
  name: "voted",
  initialState,
  extraReducers: {
    [addVotedProduct.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.voted = null
    },
    [addVotedProduct.rejected]: (state, action) => {
      state.isLoader = false;
      state.voted = null
    },
    [getVotedProducts.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.voted = action.payload
    },
    [getVotedProducts.rejected]: (state, action) => {
      state.isLoader = false;
      state.voted = null
    },
    [updateVotedProduct.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.voted = action.payload
    },
    [updateVotedProduct.rejected]: (state, action) => {
      state.isLoader = false;
      state.voted = null
    },
    [deleteVotedProduct.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.voted = action.payload
    },
    [deleteVotedProduct.rejected]: (state, action) => {
      state.isLoader = false;
      state.voted = null
    }
  },
});

const { reducer } = VotedProductSlice;
export default reducer;