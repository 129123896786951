import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/banner.service";

export const addBanner = createAsyncThunk(
  "banner/add", async ({ title, url, description, image, imageMobile, isActive }, thunkAPI) => {

    try {
      const data = await AuthService.addBanner(title, url, description, image, imageMobile, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return { banner: data.docs };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBanner = createAsyncThunk(
  "banner/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getBanner();
      return { banners: data.docs };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateBanner = createAsyncThunk(
  "banner/update", async ({ id, isActive }, thunkAPI) => {

    try {
      const data = await AuthService.updateBanner(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return { banners: data.docs };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateBannerData = createAsyncThunk(
  "banner/updateInfo", async ({ id, title, url, description, image, oldImage, imageMobile, oldImageMobile }, thunkAPI) => {
    try {
      const data = await AuthService.updateBannerData(id, title, url, description, image, oldImage, imageMobile, oldImageMobile);
      thunkAPI.dispatch(setMessage(data.message));
      return { banners: data.docs };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  });

export const deleteBanner = createAsyncThunk(
  "banner/delete", async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteBanner(id);
      thunkAPI.dispatch(setMessage(data.message));
      return { banners: data.docs };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }

);

const initialState = { isLoader: false, banner: null, banners: null };

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  extraReducers: {
    [addBanner.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.banner = action.payload
    },
    [addBanner.rejected]: (state, action) => {
      state.isLoader = false;
      state.banner = null
    },
    [getBanner.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.banners = action.payload
    },
    [getBanner.rejected]: (state, action) => {
      state.isLoader = false;
      state.banners = null
    },
    [updateBanner.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.banners = action.payload
    },
    [updateBanner.rejected]: (state, action) => {
      state.isLoader = false;
      state.banners = null
    },
    [updateBannerData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.banners = action.payload
    },
    [updateBannerData.rejected]: (state, action) => {
      state.isLoader = false;
      state.banners = null
    },
    [deleteBanner.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.banners = action.payload
    },
    [deleteBanner.rejected]: (state, action) => {
      state.isLoader = false;
      state.banners = null
    }
  },
});

const { reducer } = bannerSlice;
export default reducer;